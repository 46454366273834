
import sortBy from 'lodash/sortBy';
import { makeObservable, observable } from 'mobx';

export default class CommsStore {
  @observable emailsById = new Map();
  @observable emailsByPartyId = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.comms;
  }

  initialize = () => {};

  fetchEmails = async (transactionId, parties, offset, limit) => {
    const partyIds = (parties || []).map((p) => p.id);
    const { data } = await this.api.fetchEmails(transactionId, {
      parties: partyIds,
      offset,
      limit,
    });
    const emails = data.data;
    this.updateEmails(emails);
    return data;
  };

  // TODO: add a getFetch function, so we don't fetch every time

  updateEmails = (emails) => {
    const toSort = new Set();
    emails.forEach((email) => {
      this.emailsById.set(email.id, email);

      // TODO: delete this assignment when implement email.parties in backend
      email.parties = [];
      email.parties.forEach((partyId) => {
        if (!this.emailsByPartyId.has(partyId)) {
          this.emailsByPartyId.set(partyId, []);
        }
        const currentList = this.emailsByPartyId.get(partyId);
        const current = currentList.find((e) => e.id === email.id);
        // TODO: update if the email already exists
        if (!current) {
          currentList.push(observable(email));
        }
        toSort.add(partyId);
      });
    });
    toSort.forEach((partyId) => {
      const list = this.emailsByPartyId.get(partyId);
      const sorted = sortBy(list, (a) => -1 * +a.createdAt);
      this.emailsByPartyId.set(partyId, sorted);
    });
  };
}
