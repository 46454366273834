import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, ItemJson } from './item';

export interface StepStore extends ItemStore {
  itemsById: Map<string, unknown>;
}

export interface StepJson extends ItemJson {
  kind: 'STEP';
  step: {
    state: string;
    stepKind: string;
    status: string;
  };
}

export default class Step extends Item<StepStore, StepJson> {
  constructor(store: StepStore, json: StepJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get state() {
    return this.kindItem.state;
  }

  @computed
  get stepKind() {
    return this.kindItem.stepKind;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get ownerId() {
    return this.outEdges
      .filter((e) => e.kind === 'STEP_HAS_OWNER')
      .map((e) => e.item2Id)[0];
  }

  @computed
  get owner() {
    return this.ownerId ? this.store.itemsById.get(this.ownerId) : null;
  }
}
