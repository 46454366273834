import { computed, makeObservable } from 'mobx';
import { Contact } from 'src/types/proto/contacts';
import Item, { ItemStore as EnvelopeRecipientStore, ItemJson } from './item';

export interface EnvelopeRecipientJson extends ItemJson {
  kind: 'ENVELOPE_RECIPIENT';
  envelopeRecipient: {
    contact: Contact;
    index: string;
  };
}

export default class EnvelopeRecipient extends Item<
  EnvelopeRecipientStore,
  EnvelopeRecipientJson
> {
  constructor(store: EnvelopeRecipientStore, json: EnvelopeRecipientJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get contact() {
    return this.kindItem.contact;
  }

  @computed
  get index() {
    return this.kindItem.index;
  }

  @computed
  get email() {
    return this.contact.email;
  }
}

export type { EnvelopeRecipientStore };
