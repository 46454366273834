import AggregateStore from './aggregate-store';
import Envelope from 'src/models/envelopes/envelope';
import { action, computed, makeObservable } from 'mobx';

import { makeItem } from 'src/models/envelopes/items';

export default class EnvelopeStore extends AggregateStore {
  constructor(parent) {
    super(parent);

    makeObservable(this);
  }

  @computed
  get api() {
    return this.parent.api.envelopes;
  }

  get account() {
    return this.parent.account;
  }

  initialize = () => {};

  getClass = () => Envelope;

  makeItem = (item) => {
    return makeItem(this, item);
  };

  @action
  fetchStepViewModel = (envelopeId, stepId) => {
    // res might be null in case of an error, or an object
    const { ui } = this.parent;
    return this.api.fetchStepViewModel(envelopeId, stepId, !ui.isCompass);
  };

  dispatchStepAction = async (envelopeId, stepId, kind, payload) => {
    let res;
    try {
      res = await this.api.dispatchStepAction(
        envelopeId,
        stepId,
        kind,
        payload
      );
    } catch (err) {
      this.parent.ui.wentWrongFull(err);
      return null;
    }

    if (res?.viewModel?.recipient) {
      this.parent.pdfEsign.setRecipient(res?.viewModel?.recipient);
    }

    return res;
  };
}
