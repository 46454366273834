import ItemBase, {
  AggregateItemStore as ItemStore,
  AggregateItemJson as ItemJson,
} from 'src/models/aggregates/item';

export default class Item<
  TStore extends ItemStore = ItemStore,
  TJson extends ItemJson = ItemJson
> extends ItemBase<TStore, TJson> {}

export type { ItemStore, ItemJson };
