import { computed, makeObservable } from 'mobx';
import { Document } from 'src/types/proto/documents';
import Item, { ItemStore as EnvelopeDocumentStore, ItemJson } from './item';

export interface EnvelopeDocumentJson extends ItemJson {
  kind: 'ENVELOPE_DOCUMENT';
  envelopeDocument: {
    index: string;
    document: Document;
  };
}

export default class EnvelopeDocument extends Item<
  EnvelopeDocumentStore,
  EnvelopeDocumentJson
> {
  constructor(store: EnvelopeDocumentStore, json: EnvelopeDocumentJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get document() {
    return this.kindItem.document;
  }

  @computed
  get url() {
    return this.document.url;
  }

  @computed
  get index() {
    return this.kindItem.index;
  }
}

export type { EnvelopeDocumentStore };
