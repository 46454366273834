import { override } from 'mobx';
import type Folder from '../transactions/items/folder';
import Transaction, {
  OPERATION_ARCHIVE,
  OPERATION_EDIT,
  OPERATION_UNARCHIVE,
} from '../transactions/transaction';

const BUYER_PROSPECT_PROPERTY_ENABLED = 'buyer_prospect_properties_ui';

export default class BuyerProspect extends Transaction {
  isBuyerProspect = true;

  @override
  get statusLabel() {
    return '';
  }

  can = (op: string) => {
    if ([OPERATION_EDIT, OPERATION_ARCHIVE, OPERATION_UNARCHIVE].includes(op)) {
      return Transaction.prototype.can.call(this, op);
    }
    return false;
  };

  getFolders(onlyMain: boolean) {
    const { features } = this.store.parent;
    const filterBppFolders = Boolean(
      features.variation(BUYER_PROSPECT_PROPERTY_ENABLED)
    );
    const txnFolders = super.getFolders();
    return onlyMain && filterBppFolders
      ? txnFolders.filter((f) => !f.isFromBuyerProspectProperty)
      : txnFolders;
  }

  get restrictedImportDocuments() {
    return {
      __DEFAULT: {
        blacklistedTags: [],
        // whitelistedTags: window.Glide.CONSTANTS.BUYER_PROSPECT_DOCUMENT_TAGS,
        // rejectMessage:
        //   "This form can't be imported because it is a property specific document",
      },
      BUYER_PROSPECT_PROPERTY: {
        blacklistedTags: [],
      },
    };
  }

  getAddressForTd(td: { folder: Folder }) {
    const bppIds = td.folder?.inEdges
      .filter((e) => e.kind === 'BPP_HAS_FOLDER')
      .map((e) => e.item1Id);
    if (td.folder?.isFromBuyerProspectProperty && bppIds && bppIds.length > 0) {
      return this.store.itemsById.get(bppIds[0]).fullAddress;
    }
    return super.getAddressForTd(td);
  }
}
