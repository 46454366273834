import EnvelopeDocument, {
  EnvelopeDocumentStore,
  EnvelopeDocumentJson,
} from './envelope-document';
import EnvelopeRecipient, {
  EnvelopeRecipientStore,
  EnvelopeRecipientJson,
} from './envelope-recipient';
import Item, { ItemStore, ItemJson } from './item';
import Step, { StepStore, StepJson } from './step';

const ITEM_MODEL_BY_KIND = {
  ENVELOPE_DOCUMENT: EnvelopeDocument,
  ENVELOPE_RECIPIENT: EnvelopeRecipient,
  STEP: Step,
};

type StoreType =
  | ItemStore
  | EnvelopeDocumentStore
  | EnvelopeRecipientStore
  | StepStore;
type JsonType =
  | ItemJson
  | EnvelopeDocumentJson
  | EnvelopeRecipientJson
  | StepJson;
type ModelType = Item | EnvelopeDocument | EnvelopeRecipient | Step;

export function makeItem(store: StepStore, json: StepJson): Step;
export function makeItem(
  store: EnvelopeRecipientStore,
  json: EnvelopeRecipientJson
): EnvelopeRecipient;
export function makeItem(
  store: EnvelopeDocumentStore,
  json: EnvelopeDocumentJson
): EnvelopeDocument;
export function makeItem(store: ItemStore, json: ItemJson): Item;
export function makeItem(store: StoreType, json: JsonType) {
  const ItemModel = (ITEM_MODEL_BY_KIND[
    json.kind as keyof typeof ITEM_MODEL_BY_KIND
  ] || Item) as new (store: StoreType, json: JsonType) => ModelType;
  return new ItemModel(store, json);
}
