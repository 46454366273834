import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import zip from 'lodash/zip';
import BoundForm from 'src/models/fields/bound-form';
import Form from 'src/models/fields/form';
import Namespace from 'src/models/fields/namespace';
import {
  BUYER_PROSPECT_PROPERTY_NAMESPACE,
  PROPERTY_NAMESPACE,
  TRANSACTION_PACKAGE_NAMESPACE,
} from 'src/stores/pdf-annotations-store';
import {
  getFetch,
  getFetchFromFunction,
  getFetchMulti,
} from 'src/utils/get-fetch';

export default class TransactionFieldsStore {
  constructor(parent) {
    this.parent = parent;
    this.formsApi = parent.api.forms;
  }

  get transactions() {
    return this.parent.transactions;
  }

  getNamespaceItemKind(ns) {
    return (
      {
        [TRANSACTION_PACKAGE_NAMESPACE]: 'TRANSACTION_PACKAGE',
        [BUYER_PROSPECT_PROPERTY_NAMESPACE]: 'BUYER_PROSPECT_PROPERTY',
        [PROPERTY_NAMESPACE]: 'PROPERTY_INFO',
      }[ns] || 'TRANSACTION_FIELDS'
    );
  }

  getFetchTransactionReformForms = getFetchFromFunction(
    (mode, { transactionId, namespaces }) => {
      const transformData = (data) => {
        const res = fromPairs(
          toPairs(data).map(([key, value]) => {
            return [key.split(':')[0], value];
          })
        );
        return res;
      };
      const keys = namespaces.map((n) => {
        const [namespace, itemId] = isArray(n) ? n : [n];
        return [namespace, transactionId, itemId].filter(Boolean).join(':');
      });
      const load = this._getFetchTransactionForms(mode, keys);
      const promise = load.promise && load.promise.then(transformData);
      if (load.complete) {
        return {
          ...load,
          data: transformData(load.data),
          promise,
        };
      }
      return {
        ...load,
        promise,
      };
    }
  );

  getFetchStaticForms = getFetch({
    bindTo: this,
    fetcher: async () => {
      const { data } = await this.formsApi.staticForms();
      return data;
    },
  });

  getFetchUnboundTransactionForm = getFetch({
    bindTo: this,
    getMemoizeKey: ({ namespace } = {}) => namespace || '_all',
    fetcher: async ({ namespace } = {}) => {
      const staticForms = await this.getFetchStaticForms.getOrFetch();
      const allForms = Object.entries(staticForms).reduce(
        (all, [k, v]) => ({
          ...all,
          [k]: new Form(v),
        }),
        {}
      );
      return namespace ? allForms[namespace] : allForms;
    },
  });

  getFetchStaticFormsOutputs = getFetch({
    bindTo: this,
    fetcher: async () => {
      const { data } = await this.formsApi.staticFormsOutputs();
      return data;
    },
  });

  getFetchUnboundTransactionFormOutputs = getFetch({
    bindTo: this,
    getMemoizeKey: ({ namespace } = {}) => namespace || '_all',
    fetcher: async ({ namespace } = {}) => {
      const staticFormsOutputs = await this.getFetchStaticFormsOutputs.getOrFetch();
      const allForms = Object.entries(staticFormsOutputs).reduce(
        (all, [k, v]) => ({
          ...all,
          [k]: v,
        }),
        {}
      );
      return namespace ? allForms[namespace] : allForms;
    },
  });

  @getFetchMulti()
  async _getFetchTransactionForms(keys) {
    const transactionItemIds = [];
    const namespaceByTransactionItemId = {};
    keys.forEach((key) => {
      const [namespace, transactionId, itemId] = key.split(':');
      const transactionItemId = [transactionId, itemId]
        .filter(Boolean)
        .join(':');
      transactionItemIds.push(transactionItemId);
      namespaceByTransactionItemId[transactionItemId] = namespace;
    });
    const uniqueTransactionItemIds = uniq(transactionItemIds);
    const uniqueTransactionIds = uniq(
      transactionItemIds.map((t) => t.split(':')[0])
    );
    const [fieldItems, staticForms] = await Promise.all([
      Promise.all(
        uniqueTransactionItemIds.map((transactionItemId) => {
          const [transactionId, itemId] = transactionItemId.split(':');
          if (itemId) {
            return this.transactions.getOrFetchItemMulti(
              transactionId,
              this.getNamespaceItemKind(
                namespaceByTransactionItemId[transactionItemId]
              ),
              [itemId]
            );
          }
          return this.transactions.getFetchItems.getOrFetch({
            transactionId,
            kind: this.getNamespaceItemKind(),
          });
        })
      ),
      this.getFetchStaticForms.getOrFetch(),
      Promise.all(
        uniqueTransactionIds.map((transactionId) =>
          this.transactions.getFetchTransactionSettings.fetch({
            transactionId,
          })
        )
      ),
    ]);
    const fieldItemsByTransactionItemId = fromPairs(
      zip(uniqueTransactionItemIds, fieldItems)
    );
    const res = fromPairs(
      keys.map((key) => {
        const [namespace, transactionId, itemId] = key.split(':');
        const transactionItemId = [transactionId, itemId]
          .filter(Boolean)
          .join(':');
        const item = fieldItemsByTransactionItemId[transactionItemId].find(
          (i) => i.namespace === namespace
        );
        const renderOptionsFactory = () => {
          // Use a factory function isntead of just an object so that form fill
          // widgets update instantly upon updating transaction settings.
          const transactionSettings = this.transactions.getFetchTransactionSettings.get(
            {
              transactionId,
            }
          );
          return transactionSettings.renderOptions;
        };
        const form = new Form(staticForms[namespace], renderOptionsFactory);
        const namespaceObject = new Namespace(item);
        return [key, new BoundForm(namespaceObject, form)];
      })
    );
    return res;
  }
}
