
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { viewTask as viewAvid } from 'src/components/transactions/checklists/actions/view-avid';
import { viewTask as viewGfp } from 'src/components/transactions/checklists/actions/view-gfp';
import {
  getOrCreateFormBuilderFlow,
  gfpCreateAddFormsFlow,
} from 'src/models/transactions/intents';
import type { AppStore } from './app-store';

type TransactionDocument = {
  id: string;
  latestVersion: {
    formSeriesId: string;
  };
  transaction: {
    id: string;
  };
};

type TransactionForm = {
  gfpfId: string;
  avidId?: string;
  availableGfpFfcId?: string;
  avidAvailable: boolean;
  flowConfigAvailable: boolean;
};

type Transaction = {
  type: string;
  id: string;
};

const AVAILABLE_MESSAGE = 'A Guided Workflow is available for this form.';
const UNDERWAY_MESSAGE = "There's a Guided Workflow in use for this form.";

export default class TransactionFormsStore {
  parent: AppStore;
  constructor(parent: AppStore) {
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  get router() {
    return this.parent.router;
  }

  get ui() {
    return this.parent.ui;
  }

  getCreateExtendGfpAction = (transactionForm: TransactionForm, transaction: Transaction) => {
  
    const createGfp = async (event?: React.MouseEvent<HTMLElement>) => {
      event?.stopPropagation();
      const tasks = await this.transactions.getFetchItems.getOrFetch({
        transactionId: transaction.id,
        kind: 'TASK',
      });
      const task = tasks.find((t) => t.type === 'GFP');
      const gfp = task.members.find((i) => i.kind === 'GUIDED_FORM_PACKET');
      const ffcIds = [transactionForm.availableGfpFfcId];
      if (!gfp || !task.can('addForms')) {
        return viewGfp({
          event,
          router: this.router,
          task,
          transactions: this.transactions,
          ui: this.ui,
          ffcIds,
          path: 'forms/forms-index'
        });
      }
      const resp = await this.transactions.dispatch(
        gfp.transactionId,
        gfpCreateAddFormsFlow(gfp.id, ffcIds)
      );
  
      return navigateToFlow(this.router, resp.result.flow_id, { ffcIds });
    };
  
    return createGfp;
  }

  getTransactionFormOfDocument = (transactionDocument: TransactionDocument) => {
    const transactionDocumentVersion =
      transactionDocument && transactionDocument.latestVersion;

    if (
      !transactionDocumentVersion ||
      !transactionDocumentVersion.formSeriesId
    ) {
      return null;
    }

    const transactionForms = this.transactions.getFetchTransactionForms.get({
      transactionId: transactionDocument.transaction.id,
    });

    if (!transactionForms) {
      return null;
    }

    const forms = flatten(
      get(transactionForms, 'libraries', []).map((l) => [
        ...get(l, 'forms', []),
      ])
    );
    return forms.find(
      (f: { seriesId: string }) =>
        f.seriesId === transactionDocumentVersion.formSeriesId
    );
  };

  getWorkflowMessage = (transactionForm: TransactionForm) => {
    if (transactionForm.gfpfId || transactionForm.avidId) {
      return UNDERWAY_MESSAGE;
    }
    if (
      transactionForm.availableGfpFfcId ||
      transactionForm.avidAvailable ||
      transactionForm.flowConfigAvailable
    ) {
      return AVAILABLE_MESSAGE;
    }
    return null;
  };

  getWorkflowCtaAction = (
    transactionForm: TransactionForm,
    transaction: Transaction,
    transactionDocument: TransactionDocument
  ) => {
    const { router, transactions, ui } = this;

    const avidCtaAction = async (event) => {
      const tasks: Transaction[] = await transactions.getFetchItems.getOrFetch({
        transactionId: transaction.id,
        kind: 'TASK',
      });
      const task = tasks.find((t) => t.type === 'AVID');
      return viewAvid({
        event,
        router,
        task,
        transactions,
      });
    };

    let primaryCtaMessage: string;
    let primaryCtaAction: (_event?: unknown) => Promise<void>;

    if (transactionForm.gfpfId) {
      primaryCtaMessage = 'View';
      primaryCtaAction = async () => {
        const tasks: Transaction[] = await transactions.getFetchItems.getOrFetch(
          {
            transactionId: transaction.id,
            kind: 'TASK',
          }
        );
        const task = tasks.find((t) => t.type === 'GFP');
        return router.navigate('transactions.transaction.items.item.gfpf', {
          transactionId: transaction.id,
          taskId: task.id,
          gfpfId: transactionForm.gfpfId,
        } as any); // TODO: The non-standard navigate parameter is used here
      };
    } else if (transactionForm.availableGfpFfcId) {
      primaryCtaMessage = 'Start Workflow';
      primaryCtaAction = this.getCreateExtendGfpAction(transactionForm, transaction);
    } else if (transactionForm.avidId) {
      primaryCtaMessage = 'View';
      primaryCtaAction = avidCtaAction;
    } else if (transactionForm.avidAvailable) {
      primaryCtaMessage = 'Start Workflow';
      primaryCtaAction = avidCtaAction;
    }

    return {
      primaryCtaMessage,
      primaryCtaAction,
    };
  };

  getFormConfigFlowCtaAction = (
    transactionForm: TransactionForm,
    transaction: Transaction,
    transactionDocument: TransactionDocument
  ) => {
    let ctaMessage: string | undefined;
    let ctaAction: ((event) => Promise<void>) | undefined;

    if (
      !transactionForm.gfpfId &&
      transactionForm.flowConfigAvailable && transactionDocument
    ) {
      ctaMessage = 'Prepare invitation';
      ctaAction = this.getCreateExtendGfpAction(transactionForm, transaction);
    }

    return {
      ctaMessage,
      ctaAction,
    };
  };
}
